import {MenuCreateOptionInterface} from 'kernel/types/module'

class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  $t(args: string) {
    return this.vm.$t(args)
  }

  get() : {[key: string]: any} {
    return {
      admin: [
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN', 'ROLE_CONTACT']),
          label: this.vm.$t('page.contact'),
          icon: 'fa fa-comments',
          group: [
            {
              label: this.vm.$t('page.contact'),
              route: {
                name: 'contact',
              },
            },
            {
              label: this.vm.$t('page.app-config.contact'),
              route: {
                name: 'app-config-contact',
              },
            },
          ],
        },
      ],
    }
  }
}

export default () => (new menu()).get()
